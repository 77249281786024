import { useEffect, useState } from 'react';

/**
 * Determine if the device is mobile.
 * Returns true if the device is mobile
 *
 * @param {boolean} defaultValue
 *
 * @return {boolean}
 */

export default function useDetectMobileDevice(defaultValue = false) {
  const [matches, setMatches] = useState(defaultValue);

  useEffect(() => {
    const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;

    setMatches(
      /windows phone/i.test(userAgent) ||
        /android/i.test(userAgent) ||
        (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream),
    );
  }, []);

  return matches;
}
