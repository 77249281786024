import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { StyledDisclaimer } from './styles';
import { Container } from '@/components/ui/flexbox';
const Disclaimer = ({ content, hasEligibilityClaim, id }) => {
  return (
    <Container large>
      <StyledDisclaimer id={id} $hasEligibilityClaim={hasEligibilityClaim}>
        <ReactMarkdown
          allowedElements={['p', 'strong', 'em', 'a', 'u']}
          rehypePlugins={[rehypeRaw]}
          unwrapDisallowed
        >
          {content}
        </ReactMarkdown>
      </StyledDisclaimer>
    </Container>
  );
};

export default Disclaimer;
