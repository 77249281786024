import styled from 'styled-components';
import { H3 } from '@/components/ui/Title';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.8fr;
  column-gap: 80px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    grid-template-columns: 1fr;
    row-gap: 24px;
  }
`;
export const Content = styled.div`
  display: grid;
  row-gap: 24px;
  place-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    row-gap: 16px;
  }
`;
export const CustomTitle = styled(H3)`
  font-family: ${(props) => props.theme.typography.family.title};
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  padding: 0;
  max-width: 300px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 30px;
    line-height: 40px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 25px;
    line-height: 32px;
    max-width: 100%;
  }
`;
export const Description = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 16px;
    line-height: 28px;
  }
`;

export const ImageElement = styled.figure`
  position: relative;
  aspect-ratio: 5/4;

  img {
    object-fit: contain;
    border-radius: 16px;
  }
`;
