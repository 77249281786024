import { useState } from 'react';

// Components
import useReducedMotion from '@/utils/useReducedMotion';
import useIsTouchDevice from '@/utils/useIsTouchDevice';
import VideoPlayerModal from '@/components/shared/VideoPlayerModal';

// Utils
import getImgSrcWorkaround from '@/utils/images';
import PlaySVG from '@/public/icons/ic_play.svg';

// Styles
import {
  Wrapper,
  StyledContainer,
  VideoContainer,
  Content,
  TextContainer,
  Title,
  Button,
} from './styles';

const Video = ({ previewVideo, video, videoTitle }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const prefersReducedMotion = useReducedMotion();
  const isTouchDevice = useIsTouchDevice();

  return (
    <StyledContainer>
      <Wrapper>
        <VideoPlayerModal
          modalIsOpen={modalIsOpen}
          closeModal={() => setModalIsOpen(false)}
          videoURL={video?.url ?? video?.data?.attributes?.url}
        />

        <VideoContainer id="video_media" autoPlay={!prefersReducedMotion} playsInline loop muted>
          <source
            src={getImgSrcWorkaround(previewVideo?.url ?? previewVideo?.data?.attributes?.url)}
          />
        </VideoContainer>

        <Content $touch={isTouchDevice}>
          <TextContainer>
            <Title id="video_title">{videoTitle ?? previewVideo.data.attributes.caption}</Title>
            <Button id="video_button" onClick={() => setModalIsOpen(true)}>
              <PlaySVG /> Play video
            </Button>
          </TextContainer>
        </Content>
      </Wrapper>
    </StyledContainer>
  );
};
export default Video;
