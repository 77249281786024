import styled from 'styled-components';

export const ImageWrapper = styled.div`
  aspect-ratio: 16 / 9;
  img {
    object-fit: cover;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    width: 50vw;
    height: 100%;
    position: relative;
    clip-path: circle(2000px at 2000px 50%);
    aspect-ratio: unset;
  }
`;
