import cmsAxios from '@/lib/cms-axios';
import { getMoveClient } from './move.service';
import { getBloomClient } from './bloom.service';
import { getAcademyClient } from './academy.service';
import { getClient as getThriveClient } from './thrive.service';
import { getOnCallClient } from './on-call.service';
import { getPlatformClient } from './platform.service';

export async function getClient({ client, preview, productKey, draftAndPublished }) {
  switch (productKey) {
    case 'platform':
      return await getPlatformClient({ client, preview });
    case 'thrive':
      return await getThriveClient({ client, preview, draftAndPublished });
    case 'dpt':
      return await getThriveClient({ client, preview, draftAndPublished });
    case 'academy':
      return await getAcademyClient({ client, preview });
    case 'on_call':
      return await getOnCallClient({ client, preview });
    case 'move':
      return await getMoveClient({ client, preview });
    case 'bloom':
      return await getBloomClient({ client, preview, draftAndPublished });
    default:
      return await getPlatformClient({ client, preview });
  }
}

export function searchClient({ query, searchPrefix = 'clients' }) {
  return cmsAxios.get(`/${searchPrefix}/search/${encodeURIComponent(query)}`).then(({ data }) => {
    return data.data?.reduce((acc, client) => {
      if (client?.attributes?.name?.toLowerCase().includes(query.toLowerCase())) {
        acc.push({
          name: client?.attributes?.name,
          clientKey: client?.attributes?.clientKey,
          institutionKey: client?.attributes?.institutionKey,
          onboardingUrl: client?.attributes?.onboardingUrl,
        });
      }

      client?.attributes?.alias?.forEach((alias) => {
        if (alias.name?.toLowerCase().includes(query.toLowerCase())) {
          acc.push({
            name: alias.name,
            clientKey: client?.attributes?.clientKey,
            institutionKey: client?.attributes?.institutionKey,
            onboardingUrl: client?.attributes?.onboardingUrl,
          });
        }
      });

      return acc;
    }, []);
  });
}

export function getAllClients() {
  return cmsAxios.get(`/clients/list/all`).then(({ data }) => {
    return data.data;
  }, []);
}
