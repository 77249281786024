import styled from 'styled-components';

export const SliderCardStyled = styled.div`
  display: grid;
  border-radius: 16px;
  overflow: hidden;
  position: absolute;
  height: calc(100% - (var(--testimonials-margin-width) * 2));
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;

  .slide-active & {
    height: 100%;
    width: calc(100% + (var(--testimonials-margin-width) * 2));
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    border-radius: 8px;
    height: 100%;

    .slide-active & {
      width: 100%;
    }
  }
`;

export const ImageWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.neutral.default};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  img {
    object-fit: cover;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      object-position: 50% 0;
    }
  }
`;
