import styled from 'styled-components';

import theme from '@/utils/styles-variables';

import Button from './Button';

export const SecondaryButton = styled(Button).attrs({
  loadingColor: theme.colors.white,
})`
  background-color: #1f222c;
  color: ${(props) => props.theme.colors.white};

  &:hover {
    background-color: #3e424c;
  }

  &:disabled {
    background-color: #d8dbe1;
    color: #747986;
    pointer-events: none;
  }
`;
