import Image from 'next/image';
import Title from '@/components/ui/Title';
import { CardWrapper, Description, ImageWrapper, TextWrapper } from './styles';
import getImgSrcWorkaround from '@/utils/images';

export default function Card({ card }) {
  const { image, title, description } = card;

  return (
    <CardWrapper>
      <ImageWrapper>
        <Image
          src={getImgSrcWorkaround(image?.url ?? image?.data?.attributes?.url)}
          alt={image?.alternativeText ?? image?.data?.attributes?.alternativeText ?? ''}
          fill
          sizes="(max-width: 767px) 100vw, (max-width: 991px) 50vw, 790px"
        />
      </ImageWrapper>
      <TextWrapper>
        <Title as="h4">{title}</Title>
        <Description>{description}</Description>
      </TextWrapper>
    </CardWrapper>
  );
}
