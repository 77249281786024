import { useSearchParams } from 'next/navigation';

import { PrimaryButton } from '@/components/ui/Button';

import { trackEvent } from '@/lib/gtm';

import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';

import { scrollToTop } from '@/utils/scrollToSection';

import useReducedMotion from '@/utils/useReducedMotion';
import { createGuardaLink, createOnboardingURL } from '@/utils/onboarding-urls';
import { compareDates } from '@/utils/dates';

export const redirectToOnboarding = (buttonLabel, client, buttonLocation, href, triage) => {
  trackEvent('redirect_to_onboarding', {
    value: client.clientKey,
  });

  trackButtonClicked({
    action: triage ? ACTIONS.REDIRECT_TO_TRIAGE : ACTIONS.REDIRECT_TO_ONBOARDING,
    buttonType: BUTTON_TYPES.NAVIGATION,
    buttonText: buttonLabel,
    destination: href,
    buttonLocation: buttonLocation,
  });
};

export default function EnrollButton({
  id,
  isActive,
  buttonLocation,
  openModal,
  client,
  buttonLabel = 'Get started',
  scrollTop,
  product,
  redirectToApp,
  buttonApp,
  triage,
  referralUser,
}) {
  const searchParams = useSearchParams();
  const isReduced = useReducedMotion();

  if (!isActive) {
    return (
      <PrimaryButton id={id} data-testid="enroll-disabled" disabled>
        {buttonLabel}
      </PrimaryButton>
    );
  }

  if (compareDates(client?.launchDate)) {
    const params = new URLSearchParams(searchParams?.toString());
    params.append('client', client.clientKey);
    return (
      <PrimaryButton id={id} href={'/waiting-list?' + params.toString()} data-testid="enroll-soon">
        Coming Soon
      </PrimaryButton>
    );
  }

  if (referralUser) {
    const href = createOnboardingURL({
      clientKey: client.institutionKey ?? client.clientKey,
      countryCode: referralUser?.region,
      programType: product?.param,
      query: { ...Object.fromEntries(searchParams), 'referral-token': referralUser?.token },
      triage,
    });

    return (
      <PrimaryButton
        id={id}
        onClick={() => redirectToOnboarding(buttonLabel, client, buttonLocation, href, triage)}
        href={href}
        data-testid="enroll-active"
      >
        {buttonLabel}
      </PrimaryButton>
    );
  }

  if (redirectToApp && client?.clientKey) {
    const link = createGuardaLink({
      clientKey: client.institutionKey ?? client.clientKey,
      serviceApp: product?.app,
      programType: product?.param,
    });
    return (
      <PrimaryButton id={id} href={link}>
        {buttonApp?.label ?? product?.enrollLabel ?? 'Enroll Now'}
      </PrimaryButton>
    );
  }

  if (scrollTop || (redirectToApp && !client.clientKey)) {
    return (
      <PrimaryButton id={id} onClick={() => scrollToTop({ isReduced })} data-testid="enroll-scroll">
        {buttonLabel}
      </PrimaryButton>
    );
  }

  if (client?.clientKey && (!client?.countries || client?.countries?.length < 2) && buttonLabel) {
    const href = createOnboardingURL({
      clientKey: client.institutionKey ?? client.clientKey,
      countryCode: client?.country?.data?.attributes?.ISOCode,
      programType: product?.param,
      query: Object.fromEntries(searchParams),
      triage,
    });
    return (
      <PrimaryButton
        id={id}
        onClick={() => redirectToOnboarding(buttonLabel, client, buttonLocation, href, triage)}
        href={href}
        data-testid="enroll-active"
      >
        {buttonLabel}
      </PrimaryButton>
    );
  }

  return (
    <PrimaryButton id={id} onClick={openModal} data-testid="enroll-modal">
      {buttonLabel}
    </PrimaryButton>
  );
}
