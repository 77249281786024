import styled, { css } from 'styled-components';

const InputLabel = ({ children, className, htmlFor }) => (
  <label className={className} htmlFor={htmlFor}>
    {children}
  </label>
);

const InputLabelStyled = styled(InputLabel)`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 10px;
  display: block;

  ${(props) =>
    props.colored &&
    css`
      color: ${(props) => props.theme.colors.grey.default};
    `};
`;

export default InputLabelStyled;
