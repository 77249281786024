import styled from 'styled-components';

export const StyledSection = styled.section`
  position: relative;
`;

export const StyledFootnotes = styled.div`
  padding: 32px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey.light};

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 24px 0;
  }
`;

export const Title = styled.h6`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  padding-bottom: 8px;
`;

export const Number = styled.span`
  color: ${(props) => props.theme.colors.primary.default};
  width: 1em;
`;

export const Item = styled.li`
  display: flex;
  align-items: baseline;
  color: ${(props) => props.theme.colors.grey.default};
  word-wrap: break-word;
  flex-flow: wrap;
  flex: 0 1 auto;
  font-size: 14px;
  line-height: 24px;
  padding-top: 8px;
  font-weight: 500;

  a {
    text-decoration: underline;
  }
`;
export const WrapperText = styled.div`
  width: calc(100% - 18px);
  margin-left: -14px;
  padding-left: 1em;
`;
