import styled, { css } from 'styled-components';

import { TertiaryButton } from '@/components/core/Button';
import { Container } from '@/components/ui/flexbox';

export const StyledContainer = styled(Container)`
  max-width: 1672px;
  z-index: 1;
  position: relative;
`;

export const Wrapper = styled.div`
  position: relative;
  aspect-ratio: 2/1;
  margin: auto;
  border-radius: 40px;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    border-radius: 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    aspect-ratio: 4/5;
  }
`;

export const VideoContainer = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`;

export const Content = styled.div`
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  background: ${(props) => props.theme.colors.grey.dark}80;
  opacity: 0;
  transition: all 120ms ease-out;
  width: 100%;
  top: 0;
  left: 0;
  padding: 24px;

  &:hover {
    opacity: 1;
  }

  ${({ $touch }) =>
    $touch &&
    css`
      opacity: 1;
    `};
`;

export const TextContainer = styled.div`
  max-width: 360px;
  margin: auto;
  text-align: center;
`;

export const Title = styled.h5`
  font-family: ${(props) => props.theme.typography.family.title};
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: ${(props) => props.theme.colors.white};
  padding-bottom: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 25px;
    line-height: 32px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const Button = styled(TertiaryButton)`
  border-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.white};
  align-items: center;
  display: inline-flex;
  gap: 10px;

  &:hover {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.grey.dark};
  }
`;
