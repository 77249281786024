import { useRef } from 'react';
import {
  StyledSlide,
  SlideWrapper,
  ArrowsWrapper,
  CenteredArrowButton,
  CardContainer,
} from './styles';

import SvgArrow from '@/public/icons/arrow-slider.svg';

import Card from './Card';

const settings = {
  infinite: true,
  speed: 500,
  variableWidth: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        variableWidth: false,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: () => <button type="button" />,
      },
    },
  ],
};

export default function CardsSlider({ cards }) {
  const sliderRef = useRef();

  if (!cards) return null;

  return (
    <SlideWrapper>
      <ArrowsWrapper>
        <CenteredArrowButton
          onClick={() => sliderRef.current.slickPrev()}
          $flip
          type="button"
          data-testid="buttonPrev"
          aria-label="Previous button"
        >
          <SvgArrow aria-hidden={true} />
        </CenteredArrowButton>

        <CenteredArrowButton
          onClick={() => sliderRef.current.slickNext()}
          type="button"
          aria-label="Next button"
          data-testid="buttonNext"
        >
          <SvgArrow aria-hidden={true} />
        </CenteredArrowButton>
      </ArrowsWrapper>
      <StyledSlide ref={sliderRef} {...settings}>
        {cards.map((card) => (
          <CardContainer key={card.id}>
            <Card card={card} />
          </CardContainer>
        ))}
      </StyledSlide>
    </SlideWrapper>
  );
}
