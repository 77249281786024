import styled from 'styled-components';
import GradientRingShape from '@/public/assets/shapes/gradient_ring_shape.svg';

export const CircleFilled = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  background: ${(props) => props.color};
  border-radius: 50%;
  z-index: -1;
`;

export const CustomGradientRingShape = styled(GradientRingShape)`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    display: block;
    position: absolute;
    top: -320px;
    left: -378px;
    width: 787px;
    height: 787px;
    z-index: -1;
  }
`;

export const CustomCircleFilled = styled(CircleFilled)`
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    display: block;
    position: absolute;
    bottom: -44px;
    right: -91px;
    z-index: 0;
    width: 219px;
    height: 219px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    bottom: -50px;
    right: -55px;
    z-index: 0;
    width: 303px;
    height: 303px;
  }
`;
