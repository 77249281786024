import { components } from 'react-select';
import Image from 'next/image';

export function DropdownIndicator(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <Image src="/icons/arrow_down.svg" width={13} height={7} alt="arrow down" />
    </components.DropdownIndicator>
  );
}
