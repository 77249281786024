import React, { useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import InputLabel from '@/components/core/form/input/input-label';

import { DropdownIndicator } from './dropdown.styles';
import { baseStyles } from '../CompaniesDropdown/dropdown.styles';
import ErrorLabel from './ErrorLabel';
import { CustomValueContainer } from '../CustomValueContainer';

const Dropdown = ({
  className,
  label,
  ariaLabel,
  name,
  options,
  value,
  onChange,
  onBlur,
  placeholder,
  field,
  error,
  errorLabel,
  disabled,
  isSearchable = true,
  onInputChange,
  noOptionsMessage,
  hideDropdownIndicator,
  customBaseStyles,
  onFocus,
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <div className={className}>
      {label && <InputLabel htmlFor={field ? field.name : name}>{label}</InputLabel>}

      <Select
        instanceId={field ? field.name : name}
        aria-label={ariaLabel}
        options={options}
        value={value}
        onChange={(option) => onChange(option)}
        onInputChange={onInputChange}
        noOptionsMessage={noOptionsMessage}
        onFocus={() => {
          setFocused(true);
          onFocus;
        }}
        onBlur={() => {
          setFocused(false);
          onBlur;
        }}
        isFocused={focused}
        placeholder={placeholder}
        components={
          hideDropdownIndicator
            ? {
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                ValueContainer: CustomValueContainer,
              }
            : { DropdownIndicator, ValueContainer: CustomValueContainer }
        }
        styles={customBaseStyles ? customBaseStyles({ error }) : baseStyles({ error })}
        error={error}
        isDisabled={disabled}
        isSearchable={isSearchable}
        minMenuHeight={246}
        maxMenuHeight={246}
        menuPlacement="auto"
      />
      {error && errorLabel && <ErrorLabel error={error}>{errorLabel}</ErrorLabel>}
    </div>
  );
};

const DropdownStyled = styled(Dropdown)`
  margin-bottom: 16px;
`;

export default DropdownStyled;
