import Title, { Label } from '@/components/ui/Title';
import Section from '@/components/ui/Section';
import styled from 'styled-components';

export const CustomSection = styled(Section)`
  padding-bottom: 186px;
  margin-bottom: -140px;
  min-height: 893px;
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 154px;
    padding-top: 0px;
    min-height: unset;
    margin-bottom: -60px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 114px;
    padding-top: 0px;
    margin-bottom: -30px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1 0 0;
  position: relative;
  min-height: 500px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;
    min-height: 732px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;
    min-height: 560px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    min-height: 474px;
  }
`;

export const ImageWrapper = styled.div`
  width: 800px;
  height: 800px;
  position: absolute;
  right: 0;
  bottom: -160px;

  img {
    z-index: 1;
    object-fit: cover;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 696px;
    height: 696px;
    bottom: -100px;
    top: unset;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 420px;
    height: 420px;
    bottom: -84px;
    left: 0;
    margin: auto;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    bottom: -94px;
    left: -24px;
    width: 320px;
    height: 320px;
  }
`;

export const InfoWrapper = styled.div`
  width: calc(100% - 800px);
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 100%;
    min-width: unset;
  }
`;
export const CustomTitle = styled(Title)`
  font-size: 56px;
  line-height: 64px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 48px;
    line-height: 56px;
    width: 65%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 32px;
    line-height: 40px;
    width: 100%;
  }
`;
export const CustomLabel = styled(Label)`
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  padding-bottom: 0;
`;

export const DownloadNow = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    align-self: flex-start;
  }

  .row {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 16px;
    align-self: stretch;
    flex-wrap: wrap;
    flex-direction: row;
    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  a {
    cursor: pointer;
  }
`;

export const QRContainer = styled.div`
  display: flex;
  padding: 24px;
  align-items: flex-start;
  border-radius: 16px;
  background-color: ${(props) => props.theme.colors.neutral.default};

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: none;
  }
`;
