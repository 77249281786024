const DEFAULT_COUNTRY = {
  key: 'US',
  label: 'United States',
};

const COUNTRIES = [
  DEFAULT_COUNTRY,
  {
    key: 'PT',
    label: 'Portugal',
  },
  {
    key: 'UK',
    label: 'United Kingdom',
  },
  {
    key: 'CA',
    label: 'Canada',
  },
  {
    key: 'CA_EN',
    label: 'Canada (English)',
  },
  {
    key: 'CA_FR',
    label: 'Canada (French)',
  },
  {
    key: 'CR',
    label: 'Costa Rica',
  },
  {
    key: 'PR',
    label: 'Puerto Rico',
  },
];

export default function getCountry(countryName) {
  if (!countryName) return DEFAULT_COUNTRY;

  return COUNTRIES.find((ct) => ct.label === countryName) || {};
}
