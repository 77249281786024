import Link from 'next/link';
import styled from 'styled-components';

function Item({ href, label }) {
  return (
    <LinkStyle href={href} target="_blank" rel="noreferrer">
      {label}
    </LinkStyle>
  );
}

const LinkStyle = styled(Link)`
  display: flex;
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.colors.grey.dark};
  padding-top: 0 !important;

  &:hover {
    text-decoration: underline;
  }
`;

export default Item;
