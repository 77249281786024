import styled from 'styled-components';
import { wysiwyg } from '@/components/core/Accordion/wysiwyg';
import { MarkdownRichText } from '@/app/_components/shared';

const ContentStyle = styled.div`
  ${wysiwyg};
`;

const Content = ({ id, children }) => (
  <ContentStyle id={id}>
    <MarkdownRichText
      elements={[
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'ul',
        'ol',
        'li',
        'blockquote',
        'p',
        'strong',
        'em',
        'del',
        'code',
        'img',
        'a',
        'sup',
        'sub',
        'br',
      ]}
      components={{
        h1: 'h3',
        h2: 'h3',
      }}
    >
      {children}
    </MarkdownRichText>
  </ContentStyle>
);

export default Content;
