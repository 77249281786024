import { useEffect, useState } from 'react';

const isTouchDevice = () => {
  return (
    (window && 'ontouchstart' in window) ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
};

/**
 * Used to determine if the device has touch capabilities
 *
 * @param {boolean} defaultValue
 *
 * @return {boolean}
 */
export default function useIsTouchDevice(defaultValue = false) {
  const [matches, setMatches] = useState(defaultValue);

  useEffect(() => {
    setMatches(isTouchDevice());
  }, []);

  return matches;
}
