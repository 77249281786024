import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

import { Container } from '@/components/ui/flexbox';
import {
  StyledFootnotes,
  Title,
  Number,
  Item,
  WrapperText,
} from '@/app/components/platform/Footer/Footnotes/styles';

const FOOTNOTE_PREFIX = 'footnote';

export function getFootnoteId(id) {
  return `${FOOTNOTE_PREFIX}-${id}`;
}

const Footnotes = ({ content }) => {
  return (
    <Container large>
      <StyledFootnotes>
        {content && !!content.length && (
          <>
            <Title>Footnotes</Title>
            <ul>
              {content?.map((item, index) => (
                <Item key={item.label} id={getFootnoteId(index + 1)}>
                  <Number aria-hidden="true">{index + 1}</Number>
                  <WrapperText>
                    <ReactMarkdown
                      linkTarget="_blank"
                      elements={['strong', 'em', 'a']}
                      remarkPlugins={[[remarkBreaks]]}
                    >
                      {item.label}
                    </ReactMarkdown>
                  </WrapperText>
                </Item>
              ))}
            </ul>
          </>
        )}
      </StyledFootnotes>
    </Container>
  );
};

export default Footnotes;
