import Section from '@/components/ui/Section';
import { Container } from '@/components/ui/flexbox';
import { PrimaryButton } from '@/components/ui/Button';
import { scrollToSection } from '@/utils/scrollToSection';

import { Header, HeaderWrapper, Title } from './styles';

const Cta = ({ content }) => {
  const { title, cta } = content;
  const isLink = !cta.href.startsWith('#');

  return (
    <Section>
      <Container>
        <Header>
          <HeaderWrapper>
            <Title id="cta_title">{title}</Title>
          </HeaderWrapper>
          <div>
            <PrimaryButton
              id="cta_button"
              href={isLink ? cta.href : false}
              onClick={() =>
                isLink ? {} : scrollToSection({ sectionId: cta.href.replace('#', '') })
              }
            >
              {cta.label}
            </PrimaryButton>
          </div>
        </Header>
      </Container>
    </Section>
  );
};

export default Cta;
