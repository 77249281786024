import { Container, Row, Col } from '@/components/ui/flexbox';

import Item from './Links';
import Legal from './Legal';
import { Section, InnerWrapper, LogoWrapper, MailCol, Disclaimer } from './styles';

import Logo from '@/public/sword__colored.svg';

const FOOTER = {
  disclaimer:
    ' Sword Health, Inc. does not provide healthcare services. Sword Health Professionals provides clinical services through a group of independently owned professional practices consisting of Sword Health Care Providers, P.A., Sword Health Care Providers of NJ, P.C., and Sword Health Care Physical Therapy Providers of CA, P.C.',
  supportEmail: 'help@swordhealth.com',
};

const Footer = ({ email, extraPadding = false }) => {
  const helpContent = {
    href: email?.href || FOOTER.supportEmail,
    label: email?.label || FOOTER.supportEmail,
  };
  return (
    <Section as="footer">
      <Container large>
        <InnerWrapper $extraPadding={extraPadding}>
          <Row>
            <Col lg={3}>
              <LogoWrapper>
                <Logo />
              </LogoWrapper>
            </Col>
          </Row>
          <Row>
            <MailCol lg={3} xs={12}>
              <p>Need help? Contact us at</p>
              <Item href={`mailto:${helpContent.href}`} label={helpContent.label} />
            </MailCol>
            <Col lg={3} xs={12}>
              <Legal />
            </Col>
            <Col lg={6} xs={12}>
              <Disclaimer>
                {FOOTER.disclaimer}
                <p>© {new Date().getFullYear()} Sword Health, Inc. All Rights Reserved</p>
              </Disclaimer>
            </Col>
          </Row>
        </InnerWrapper>
      </Container>
    </Section>
  );
};

export default Footer;
