import { useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import { Container } from '@/components/ui/flexbox';
import { Wrapper, InnerWrapper, Title } from './styles';

import ChevronDown from '@/public/icons/chevron-down-thin.svg';

const Banner = ({ title, phone, label, email, active, expandLabel }) => {
  const [minimized, setMinimized] = useState(true);
  const [height, setHeight] = useState(49);
  const ref = useRef(null);

  const observer = useRef(null);
  useEffect(() => {
    const item = ref?.current;
    const unobserve = () => {
      observer?.current?.disconnect();
    };
    if (observer?.current) {
      unobserve();
    }
    if (item) {
      observer.current = new ResizeObserver((entries) => {
        const clientHeight = entries[0].contentRect.height;
        setHeight(clientHeight + 25);
      });
      observer.current.observe(item);
    }
    return () => unobserve();
  }, [ref]);

  if (!active) {
    return null;
  }

  return (
    <AnimateHeight id="top-banner" duration={500} height={minimized ? height : 'auto'}>
      <Wrapper>
        <Container>
          <InnerWrapper>
            <Title minimized={minimized} ref={ref} id="contacts-banner-title">
              {title}
              <button
                type="button"
                className="expand-label"
                onClick={() => setMinimized(!minimized)}
                id="contacts-banner-expand-label"
              >
                {expandLabel} <ChevronDown />
              </button>
            </Title>
            {phone && (
              <span>
                <FontAwesomeIcon id="contacts-banner-phone-icon" icon={faPhone} className="icon" />
                <a id="contacts-banner-phone-label" href={`tel:${phone.href.replace(/\s/g, '')}`}>
                  {phone.label}
                </a>{' '}
                {label}{' '}
              </span>
            )}

            {email && (
              <span>
                <FontAwesomeIcon
                  id="contacts-banner-email-icon"
                  icon={faEnvelope}
                  className="icon"
                />
                <a
                  id="contacts-banner-email-label"
                  href={`mailto:${email.href}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {email.label}
                </a>
              </span>
            )}
          </InnerWrapper>
        </Container>
      </Wrapper>
    </AnimateHeight>
  );
};

export default Banner;
