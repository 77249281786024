import styled from 'styled-components';
import cn from 'classnames';

import ErrorIcon from '../../../../public/icons/error.svg';

const ErrorLabel = ({ error, children, className }) => (
  <div className={cn(className, { error })}>
    <div className="error-icon">
      <ErrorIcon />
    </div>
    <div className="error-label">{children}</div>
  </div>
);

const ErrorLabelStyled = styled(ErrorLabel)`
  visibility: hidden;
  height: 0;
  opacity: 0;
  transition: all 0.3s ease;
  display: inline-flex;
  margin-top: 5px;

  &.error {
    visibility: visible;
    height: 14px;
    opacity: 1;
  }

  .error-icon {
    margin-right: 5px;
  }

  .error-label {
    color: ${(props) => props.theme.colors.error};
    font-size: 13px;
    line-height: 20px;
  }
`;

export default ErrorLabelStyled;
