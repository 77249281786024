import styled from 'styled-components';
import { H3, H6 } from '@/components/ui/Title';

export const Title = styled(H3)`
  max-width: 640px;
  padding-bottom: 80px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    font-size: 25px;
    line-height: 32px;
  }
`;
export const List = styled.ul`
  display: grid;
  grid-template-columns: ${(props) => (props.$colspan === 4 ? `repeat(4, 1fr)` : `repeat(3, 1fr)`)};
  column-gap: ${(props) => (props.$colspan === 4 ? `40px` : `83px`)};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: ${(props) => (props.$colspan === 4 ? `40px` : `10%`)};
    row-gap: 64px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    grid-template-columns: 1fr;
    row-gap: 40px;
  }
`;
export const Icon = styled.figure`
  position: relative;
  width: 80px;
  height: 80px;
  min-width: 80px;
  margin-bottom: 16px;
`;
export const ItemTitle = styled(H6)`
  padding-bottom: 16px;
  font-size: 20px;
  font-family: ${(props) => props.theme.typography.family.title};
  font-weight: 700;
  line-height: 24px;
  font-style: normal;
`;
export const Text = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;
