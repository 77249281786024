import * as Sentry from '@sentry/nextjs';
import { notFound } from 'next/navigation';
import { products } from '@/utils/products';

import { createOnboardingURL } from '@/utils/onboarding-urls';

export async function getClient({ client, preview = false, draftAndPublished = false }) {
  const res = preview
    ? await fetch(
        `${process.env.NEXT_PUBLIC_CMS_URL}/api/clients/findByKey/${client}?preview=true`,
        {
          cache: 'no-store',
        },
      )
    : draftAndPublished
    ? await fetch(
        `${process.env.NEXT_PUBLIC_CMS_URL}/api/clients/findByKey/${client}?draftAndPublished=true`,
        {
          cache: 'no-store',
        },
      )
    : await fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/clients/findByKey/${client}`, {
        next: { revalidate: 60 },
      });

  if (res?.status === 404) {
    notFound();
  }

  if (!res.ok) {
    Sentry.captureException(res);
    throw new Error('Failed to fetch data');
  }

  const data = await res.json();

  return {
    ...data.data.attributes,
    onboardingURL:
      data.data.attributes.onboardingURL ??
      createOnboardingURL({
        clientKey: data.data.attributes.institutionKey || data.data.attributes.clientKey,
        programType: products.THRIVE.param,
      }),
  };
}

export async function getMainPage() {
  const res = await fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/homepage/getPopulated`, {
    next: { revalidate: 60 },
  });

  if (res?.status === 404) {
    notFound();
  }

  if (!res.ok) {
    Sentry.captureException(res);
    throw new Error('Failed to fetch data');
  }

  const data = await res.json();

  return { ...data.data.attributes };
}

export async function getReferralPage() {
  const res = await fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/referral-thrive/getPopulated`, {
    next: { revalidate: 60 },
  });

  if (res?.status === 404) {
    notFound();
  }

  if (!res.ok) {
    Sentry.captureException(res);
    throw new Error('Failed to fetch data');
  }

  const data = await res.json();

  return { ...data.data.attributes };
}
