import styled, { css } from 'styled-components';

const arrowCSS = css`
  cursor: pointer;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  border: 1px solid ${(props) => props.theme.colors.grey.dark};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
`;

export const NextArrow = styled.button`
  ${arrowCSS};

  right: 0;
`;

export const PrevArrow = styled.button`
  ${arrowCSS};

  left: 0;

  & svg {
    transform: rotate(180deg);
  }
`;

export const Dots = styled.div`
  display: none;
  gap: 8px;
  justify-content: center;
  padding-top: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    display: flex;
  }
`;

export const DotButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid ${(props) => props.theme.colors.grey.default};
  border-radius: 50%;

  &[aria-selected='true'] {
    background-color: ${(props) => props.theme.colors.grey.default};
  }
`;

export const TestimonialsSliderWrapper = styled.div`
  position: relative;
  padding-left: 80px;
  padding-right: 80px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-left: 0;
    padding-right: 0;
    margin-left: -36px;
    margin-right: -36px;
  }
`;

export const Slider = styled.div`
  --testimonials-mask-width: 200px;
  --testimonials-margin-width: 24px;
  --testimonial-width: calc(420px - (var(--testimonials-margin-width) * 2));
  cursor: grab;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 1) var(--testimonials-mask-width),
    rgba(255, 255, 255, 1) calc(100% - var(--testimonials-mask-width)),
    rgba(255, 255, 255, 0) 100%
  );

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    mask-image: none;
  }

  .embla__container {
    align-items: center;
    display: flex;
    touch-action: pan-y;
  }

  .embla__slide {
    height: 525px;
    flex: 0 0 auto;
    width: var(--testimonial-width);
    margin: 0 var(--testimonials-margin-width);
    pointer-events: none;
    position: relative;

    &.slide-active {
      pointer-events: auto;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      --testimonials-margin-width: 6px;
      --testimonial-width: 272px;

      height: 340px;
    }
  }
`;
