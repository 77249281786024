import Image from 'next/image';
import { useContext } from 'react';

import Section from '@/components/ui/Section';
import { Container } from '@/components/ui/flexbox';
import getImgSrcWorkaround from '@/utils/images';
import { Wrapper, Content, CustomTitle, Description, ImageElement } from './style';

import EnrollButton from '@/app/components/core/EnrollButton';

import ClientContext from '@/contexts/client.context';

const Movement = ({ content }) => {
  const { title, description, image, buttonLabel = 'Get started for free' } = content;
  const { client, openModal } = useContext(ClientContext);

  return (
    <Section>
      <Container large>
        <Wrapper>
          <Content>
            <CustomTitle id="movement_title">{title}</CustomTitle>
            <Description id="movement_description">{description}</Description>
            <div>
              <EnrollButton
                id="movement_button"
                isActive={client.isActive}
                buttonLocation="movement"
                openModal={() => openModal({ buttonText: buttonLabel, buttonLocation: 'movement' })}
                client={client}
                buttonLabel={buttonLabel}
                triage={client?.hasTriage}
                product={client?.product}
                redirectToApp={client?.redirectToApp}
              />
            </div>
          </Content>
          <ImageElement>
            <Image
              id="movement_image"
              src={getImgSrcWorkaround(image?.url ?? image?.data?.attributes?.url)}
              alt={image?.alternativeText ?? image?.data?.attributes?.alternativeText ?? ''}
              fill
            />
          </ImageElement>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default Movement;
