import { useContext } from 'react';

import EnrollButton from '@/app/components/core/EnrollButton';

import { StyledCTA, Title } from './styles';
import ClientContext from '@/contexts/client.context';
import { Container } from '@/components/ui/flexbox';
import { products } from '@/utils/products';

const CTAFeatured = ({ content, id, scrollTop = false, referralUser }) => {
  const { client, openModal } = useContext(ClientContext);

  if (!content) {
    return null;
  }

  const { title, button } = content;

  return (
    <Container large>
      <StyledCTA>
        <Title id={`${id}-title`}>{title}</Title>
        <EnrollButton
          id={`${id}-enroll-button`}
          isActive={client.isActive}
          buttonLocation="footer"
          openModal={() => openModal({ buttonText: button?.label, buttonLocation: 'footer' })}
          client={client}
          buttonLabel={button?.label}
          scrollTop={scrollTop}
          product={products[client?.product?.key?.toUpperCase()]}
          redirectToApp={client?.redirectToApp}
          buttonApp={button}
          triage={client?.hasTriage}
          referralUser={referralUser}
        />
      </StyledCTA>
    </Container>
  );
};

export default CTAFeatured;
