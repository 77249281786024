import axios from 'axios';

class Singleton {
  constructor() {
    if (!Singleton.instance) {
      Singleton.instance = axios.create({
        baseURL: process.env.NEXT_PUBLIC_CMS_URL + '/api',
      });
    }
  }

  getInstance() {
    return Singleton.instance;
  }
}

export default new Singleton().getInstance();
