import styled, { css } from 'styled-components';

export const SPACE_DESKTOP = '136px';
export const SPACE_TABLET = '104px';
export const SPACE_MOBILE = '64px';

const Section = styled.section`
  padding-bottom: ${SPACE_DESKTOP};
  overflow-x: clip;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: ${SPACE_TABLET};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: ${SPACE_MOBILE};
  }

  ${(props) =>
    props.colored &&
    css`
      background-color: ${(props) => props.theme.colors.neutral.default};
    `}

  ${(props) =>
    props.rounded &&
    css`
      clip-path: circle(4000px at bottom);
    `}

  ${(props) =>
    props.lastColored &&
    props.colored &&
    css`
      margin-bottom: ${SPACE_DESKTOP};

      @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
        margin-bottom: ${SPACE_TABLET};
      }

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        margin-bottom: ${SPACE_MOBILE};
      }
    `}

  ${(props) =>
    props.md &&
    css`
      padding-bottom: ${props.md}px;
    `};

  ${(props) =>
    props.spacerTop &&
    css`
      padding-top: ${SPACE_DESKTOP};
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    ${(props) =>
      props.sm &&
      css`
        padding-bottom: ${props.sm}px;
      `};

    ${(props) =>
      props.spacerTop &&
      css`
        padding-top: ${SPACE_TABLET};
      `};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    ${(props) =>
      props.xs &&
      css`
        padding-bottom: ${props.xs}px;
      `};
    ${(props) =>
      props.spacerTop &&
      css`
        padding-top: ${SPACE_MOBILE};
      `};
  }
`;

export default Section;
