import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Wrapper } from './styles';
import { UpperText } from '@/components/ui/Title';
import { Heading, Paragraph } from '@swordhealth/ui-corporate';

const PanelText = ({ index, select, content, isDesktop }) => {
  const [ref, inView] = useInView({
    rootMargin: '-50% 0% -50% 0%',
    threshold: 0,
  });

  useEffect(() => {
    if (inView && index !== undefined && isDesktop) {
      select(index);
    }
  }, [inView]);

  const { title, description, label } = content;

  return (
    <Wrapper ref={ref}>
      {label && (
        <UpperText as="p" className="label">
          {label}
        </UpperText>
      )}
      <Heading variant={index === 0 ? '3' : '4'}>{title}</Heading>
      <Paragraph as="p">{description}</Paragraph>
    </Wrapper>
  );
};
export default PanelText;
