import { QuoteWrapper, Title, Text } from './style';

import QuoteIcon from '@/public/icons/icon-quote.svg';

const Quote = ({ id, name, condition, quote, withImage }) => (
  <QuoteWrapper className={withImage ? 'image' : 'text'}>
    <QuoteIcon className="icon" />
    <Title id={`${id}_title`} data-testid="quote">
      {quote}
    </Title>
    {(name || condition) && (
      <footer>
        {name && (
          <Text id={`${id}_name`} data-testid="quote-name">
            {name}
          </Text>
        )}
        {condition && (
          <Text id={`${id}_condition`} data-testid="quote-condition" $light>
            {condition}
          </Text>
        )}
      </footer>
    )}
  </QuoteWrapper>
);

export default Quote;
