import Image from 'next/image';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.min_xl}px) {
    position: absolute;
    height: calc(100vh - 100px);
    max-height: 683px;
    width: calc(100vh - 100px);
    max-width: 683px;
    top: 0;
    left: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    margin-left: 13px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin-left: 0px;
  }
`;

export const TempImageWrapper = styled.div`
  overflow: clip;
  position: relative;
  height: 0;
  padding-bottom: 100%;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  &.selected {
    opacity: 1;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    opacity: 1;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    ${(props) =>
      props.$mobileSpace &&
      css`
        padding-bottom: 80%;
      `};
  }
`;
export const WrapperVideos = styled.div`
  position: relative;
  max-height: 683px;
  padding-bottom: 100%;
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    ${(props) =>
      props.$mobileSpace &&
      css`
        padding-bottom: 80%;
      `};
  }
`;
export const VideoContainer = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
  pointer-events: none;
`;
export const CustomImage = styled(Image)`
  opacity: 0;
  transition: opacity 120ms ease-in-out;
  object-fit: contain;
  object-position: center center;
  ${(props) =>
    props.$active &&
    css`
      opacity: 1;
    `};
`;
