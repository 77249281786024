/**
 * @param {string} mediaQueryString
 * @param {Record<string, unknown>} [queryParams]
 *
 * @return {string}
 */
export function injectQueryParamsToUrl(url, queryParams) {
  try {
    const urlObject = new URL(url);

    if (queryParams) {
      const params = new URLSearchParams(queryParams);

      params.forEach((value, key) => {
        urlObject.searchParams.set(key, value);
      });
    }

    return urlObject.toString();
  } catch {
    return url;
  }
}
