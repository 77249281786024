import Section from '@/components/ui/Section';
import { H3 } from '@/components/ui/Title';
import styled from 'styled-components';

export const StyledCTA = styled(Section)`
  text-align: center;
  padding: 88px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey.light};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 64px 0;
  }
`;

export const Title = styled(H3)`
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) and min-width: ${(props) =>
      props.theme.breakpoints.min_md}px {
    font-size: 30px;
    line-height: 40px;
  }
`;
