import { trackEvent } from '@/lib/segment';

export const ACTIONS = {
  REDIRECT_TO_ONBOARDING: 'redirect_to_onboarding',
  REDIRECT_TO_PRODUCT_LP: 'redirect_to_product_lp',
  REDIRECT_TO_SIGN_IN: 'redirect_to_sign_in',
  REDIRECT_TO_TRIAGE: 'redirect_to_triage',
  REDIRECT_TO_APP: 'redirect_to_app',
  OPEN_MODAL: 'open_modal',
  NAVIGATION: 'navigation',
  WAITING_LIST_FORM_SUBMITTED: 'waiting_list_form_submitted',
  NOT_COVERED_FORM_SUBMITTED: 'not_covered_form_submitted',
};

export const BUTTON_TYPES = {
  NAVIGATION: 'navigation',
  SUBMIT: 'submit',
};

const EVENT_NAMES = {
  BUTTON_CLICKED: 'Button Clicked',
  CLIENT_ELIGIBILITY_CHECKED: 'Client Eligibility checked',
  FORM_SUBMIT: 'Form submitted',
};

export function trackButtonClicked({
  action,
  buttonText,
  buttonLocation = '',
  buttonType = '',
  destination = '',
  url,
  pageTitle = '',
}) {
  let title = pageTitle;

  if (url && !pageTitle) {
    const urlSplit = url.split('/');
    title = urlSplit[urlSplit.length - 1].replace('-', ' ');
  }

  trackEvent(EVENT_NAMES.BUTTON_CLICKED, {
    action,
    button_text: buttonText,
    button_type: buttonType,
    button_location: buttonLocation,
    page_url: url,
    destination,
    page_title: title,
  });
}

export function trackClientEligibilityChecked({
  clientName,
  clientId,
  clientCountry,
  clientExists,
}) {
  trackEvent(EVENT_NAMES.CLIENT_ELIGIBILITY_CHECKED, {
    client_name: clientName,
    client_id: clientId,
    client_country: clientCountry,
    client_exists: clientExists,
  });
}

export function trackConditionSelected({
  action,
  buttonText,
  buttonLocation = '',
  buttonType = '',
  destination = '',
  url,
  conditionName,
  productName,
}) {
  trackEvent(EVENT_NAMES.BUTTON_CLICKED, {
    action,
    button_text: buttonText,
    button_type: buttonType,
    button_location: buttonLocation,
    page_url: url,
    destination,
    condition: conditionName,
    program_name: productName,
  });
}

export function trackFormSubmitted({
  action,
  buttonText,
  buttonLocation = '',
  buttonType = '',
  client,
  consent = false,
}) {
  trackEvent(EVENT_NAMES.FORM_SUBMIT, {
    action,
    button_text: buttonText,
    button_type: buttonType,
    button_location: buttonLocation,
    client_name: client,
    consent_to_reach_client: consent,
  });
}
