import styled, { css } from 'styled-components';

export const StyledEligibilityClaim = styled.div`
  padding: ${(props) => (props.$hasDisclaimer ? '32px 0 0' : '32px 0')};

  ${(props) =>
    !props.$hasDisclaimer &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.colors.grey.light};
    `}

  p {
    font-weight: 500;
    line-height: 20px;
    font-size: 12px;
    color: ${(props) => props.theme.colors.grey.default};
    max-width: ${(props) => props.theme.flexboxgrid.containerWidth}px;
    a {
      text-decoration: underline;
    }
  }
`;
