import { Container } from '@/components/ui/flexbox';
import MetricCard from './MetricCard';
import { Metrics, CustomTitle } from './styles';

import Section from '@/components/ui/Section';

const ResultsYouCanFeel = ({ content }) => {
  const { title, metrics } = content;

  return (
    <Section>
      <Container large>
        <CustomTitle id="results_title">{title}</CustomTitle>
        <Metrics>
          {metrics.map((m, index) => (
            <MetricCard {...m} id={`results_metric_${m.id}`} key={index} />
          ))}
        </Metrics>
      </Container>
    </Section>
  );
};
export default ResultsYouCanFeel;
