import { memo } from 'react';
import Image from 'next/image';
import { getImgData } from '@/utils/images';

import Quote from '../Quote';

import { SliderCardStyled, ImageWrapper } from './style';
import theme from '@/utils/styles-variables';

const SliderCard = ({ id, picture, isNextSlide = false, ...quote }) => {
  const image = getImgData(picture?.[0] || picture?.data?.[0]);

  return (
    <SliderCardStyled>
      <ImageWrapper>
        {image && (
          <Image
            id={`${id}_image`}
            loading={isNextSlide ? 'eager' : 'lazy'}
            data-testid="slider-image"
            src={image?.url}
            alt={image?.alternativeText || ''}
            fill
            sizes={`(max-width: ${theme.breakpoints.max_sm}px) 100vw, ${theme.breakpoints.max_md}px 50vw, 420px`}
          />
        )}
      </ImageWrapper>
      <Quote {...quote} id={`${id}_quote`} withImage={!!image} />
    </SliderCardStyled>
  );
};

export default memo(SliderCard);
