import * as Sentry from '@sentry/nextjs';
import { notFound } from 'next/navigation';

import { createOnboardingURL } from '@/utils/onboarding-urls';
import { products } from '@/utils/products';

export async function getMoveClient({ client, preview = false }) {
  const res = preview
    ? await fetch(
        `${process.env.NEXT_PUBLIC_CMS_URL}/api/move-client/findByKey/${client}?preview=true}`,
        {
          cache: 'no-store',
        },
      )
    : await fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/move-client/findByKey/${client}`, {
        next: { revalidate: 60 },
      });

  if (res?.status === 404) {
    notFound();
  }

  if (!res.ok) {
    Sentry.captureException(res);
    throw new Error('Failed to fetch data');
  }

  const data = await res.json();

  return {
    ...data.data.attributes,
    onboardingURL:
      data.data.attributes.onboardingURL ??
      createOnboardingURL({
        clientKey: data.data.attributes.institutionKey || data.data.attributes.clientKey,
        programType: products.MOVE.param,
      }),
  };
}
export async function getMoveMainClient() {
  const res = await fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/move-main-page/getPopulated`, {
    next: { revalidate: 60 },
  });

  if (res?.status === 404) {
    notFound();
  }

  if (!res.ok) {
    Sentry.captureException(res);
    throw new Error('Failed to fetch data');
  }

  const data = await res.json();

  return { ...data.data.attributes };
}

export async function getMoveReferralPage() {
  const res = await fetch(`${process.env.NEXT_PUBLIC_CMS_URL}/api/referral-move/getPopulated`, {
    next: { revalidate: 60 },
  });

  if (res?.status === 404) {
    notFound();
  }

  if (!res.ok) {
    Sentry.captureException(res);
    throw new Error('Failed to fetch data');
  }

  const data = await res.json();

  return { ...data.data.attributes };
}
