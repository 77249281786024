import styled, { css } from 'styled-components';

import { PrimaryButton } from '@/components/ui/Button';

export const Form = styled.form`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 24px;

  .companiesDropdown {
    background: white;
    border-radius: 1000px;
    border: unset;
    outline: none !important;
  }
  ${(props) =>
    props.xl &&
    css`
      max-width: 700px;
      text-align: center;
      margin: auto;
    `};

  ${PrimaryButton} {
    font-family: ${(props) => props.theme.typography.family.title};
    padding: 13px 32px;
    font-size: 18px;
    line-height: 28px;
    max-height: 56px;
    margin-top: 4px;
    width: fit-content;
    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      margin-top: 0;
      max-height: 48px;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    flex-direction: column;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    text-align: center;
    margin: auto;
  }
  button {
    padding: 12px 32px;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
`;

export const Field = styled.div`
  position: relative;
  width: 320px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 100%;
  }

  > * {
    margin-bottom: 0 !important;
  }
`;
export const ButtonCheckAgain = styled(PrimaryButton)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 12px 32px;
`;
