import { useState } from 'react';
import Section from '@/components/ui/Section';
import { Container } from '@/components/ui/flexbox';
import { H3, UpperText } from '@/components/ui/Title';
import { GraphicElements } from './GraphicElements';

import { Header, HeaderText, CustomAccordion, CustomAccordionItem, StyledContent } from './styles';

const Faqs = ({ content }) => {
  const { title, upperLabel, description, accordion } = content;
  const [extendedAccordionItemIndex, setExtendedAccordionItemIndex] = useState(null);

  if (!content?.isActive) return null;

  const handleAccordionToggle = (i) => {
    setExtendedAccordionItemIndex(extendedAccordionItemIndex === i ? null : i);
  };

  return (
    <Section id="faqs" md="136" xs="64">
      <Container small>
        <Header>
          {upperLabel && <UpperText id="faq-uppertext">{upperLabel}</UpperText>}
          <H3 id="faq-title">{title}</H3>
          {description && <HeaderText id="faq-description">{description}</HeaderText>}
        </Header>
        <StyledContent>
          <GraphicElements />
          <CustomAccordion>
            {accordion?.map((a, i) => (
              <CustomAccordionItem
                id={`faq-item-${i}`}
                key={a.id}
                title={a.title}
                expanded={i === extendedAccordionItemIndex}
                handleClick={() => handleAccordionToggle(i)}
              >
                {a.description}
              </CustomAccordionItem>
            ))}
          </CustomAccordion>
        </StyledContent>
      </Container>
    </Section>
  );
};

export default Faqs;
