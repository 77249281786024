import styled from 'styled-components';

import { Col } from '@/components/ui/flexbox';

export const Section = styled.section`
  position: relative;
  padding: 80px 0;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 64px 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 40px 0;
  }
`;

export const InnerWrapper = styled.div`
  color: ${(props) => props.theme.colors.grey.default};
  padding-bottom: 80px;

  font-size: 14px;
  line-height: 24px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 64px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-bottom: 40px;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  height: 24px;
  margin-bottom: 32px;

  svg {
    color: ${(props) => props.theme.colors.brand};
  }
`;

export const MailCol = styled(Col)`
  a {
    display: inline-block;
    padding-top: 5px;
    vertical-align: middle;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 32px;

    p {
      padding-right: 4px;
    }
  }
`;

export const Disclaimer = styled.div`
  p {
    padding-top: 12px;
  }
`;
