import Title, { Label } from '@/components/ui/Title';
import { HeaderWrapper } from './styles';

export default function Header({ content }) {
  const { title, subtitle } = content;

  return (
    <HeaderWrapper>
      <Title as="h2">{title}</Title>
      <Label $medium>{subtitle}</Label>
    </HeaderWrapper>
  );
}
