import styled from 'styled-components';

export const LogosLine = styled.div`
  display: flex;
  row-gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 32px;

  &.centered {
    justify-content: center;
  }

  &.headerContent {
    padding-bottom: 12px;
  }

  &.platform {
    padding-top: 80px;
    padding-bottom: 40px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      padding-top: 64px;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      padding-top: 40px;
    }
  }
`;

export const Logo = styled.div`
  position: relative;
  display: flex;

  img {
    height: auto;
    width: auto;
    object-fit: contain;
  }

  &.client-logo {
    img {
      max-height: 64px;
      max-width: 224px;
      min-height: 33px;

      @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
        max-height: 48px;
        max-width: 160px;
      }
    }
  }

  &.sword-logo {
    img {
      max-height: 28px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      img {
        max-height: 20px;
      }
    }
  }

  &.sub-brand-logo {
    img {
      max-height: 48px;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      img {
        max-height: 32px;
      }
    }
  }
`;

export const SplitBar = styled.div`
  height: 32px;
  width: 2px;
  background: ${(props) => props.theme.colors.grey.light};
  margin-left: 20px;
  margin-right: 20px;
`;
