import { useCallback, useEffect, useId, useState } from 'react';
import classNames from 'classnames';
import useEmblaCarousel from 'embla-carousel-react';

import SliderCard from './SliderCard';
import { TestimonialsSliderWrapper, Slider, NextArrow, PrevArrow, Dots, DotButton } from './styles';

import SvgArrow from '@/public/icons/arrow-slider.svg';

const TestimonialsSlider = ({ quotes, CustomCard }) => {
  const sliderId = useId();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'center',
    containScroll: false,
    duration: 20,
    loop: true,
  });

  const isCurrentSlide = useCallback((index) => index === selectedIndex, [selectedIndex]);
  const isNextSlide = useCallback(
    (index) => {
      const nextSlide = selectedIndex + 1;
      const isNextSlide = quotes.length - 1 < nextSlide ? 0 : nextSlide;

      return isNextSlide || index === selectedIndex - 1;
    },
    [selectedIndex, quotes],
  );
  const isPrevSlide = useCallback(
    (index) => {
      const prevSlide = selectedIndex - 1;
      const isPrevSlide = prevSlide <= 0 ? quotes.length - 1 : prevSlide;

      return isPrevSlide || index === selectedIndex + 1;
    },
    [selectedIndex, quotes],
  );

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
  const scrollTo = useCallback((index) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);
  const onSelect = useCallback(() => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  return (
    <TestimonialsSliderWrapper>
      <Slider ref={emblaRef}>
        <ul
          className="embla__container"
          id={sliderId}
          aria-live="polite"
          aria-roledescription="carousel"
        >
          {quotes.map((slide, index) => (
            <li
              id={`${sliderId}-${index}`}
              aria-hidden={!isCurrentSlide(index)}
              aria-roledescription="slide"
              aria-label={`${index + 1} of ${quotes.length}`}
              key={slide.id}
              className={classNames({
                embla__slide: true,
                'slide-active': isCurrentSlide(index),
              })}
            >
              {CustomCard ? (
                <CustomCard {...slide} id={`testimonial_card_${slide.id}`} />
              ) : (
                <SliderCard
                  id={`testimonial_card_${slide.id}`}
                  name={slide.author}
                  condition={slide.role}
                  quote={slide.quote}
                  picture={slide.picture}
                  isNextSlide={isPrevSlide(index) || isNextSlide(index)}
                />
              )}
            </li>
          ))}
        </ul>
      </Slider>
      <PrevArrow onClick={scrollPrev} aria-controls={sliderId} aria-label="Previous Testimonial">
        <SvgArrow />
      </PrevArrow>
      <NextArrow onClick={scrollNext} aria-controls={sliderId} aria-label="Next Testimonial">
        <SvgArrow />
      </NextArrow>
      <Dots>
        {quotes.map((_, index) => (
          <DotButton
            key={index}
            onClick={() => scrollTo(index)}
            aria-controls={`${sliderId}-${index}`}
            aria-selected={isCurrentSlide(index)}
            aria-label={`Testimonial ${index + 1}`}
          />
        ))}
      </Dots>
    </TestimonialsSliderWrapper>
  );
};

export default TestimonialsSlider;
