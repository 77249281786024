import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { StyledEligibilityClaim } from './styles';
import { Container } from '@/components/ui/flexbox';
const EligibilityClaim = ({ content, id, hasDisclaimer }) => {
  return (
    <Container large>
      <StyledEligibilityClaim id={id} $hasDisclaimer={hasDisclaimer}>
        <ReactMarkdown
          allowedElements={['p', 'strong', 'em', 'a', 'u']}
          rehypePlugins={[rehypeRaw]}
          unwrapDisallowed
        >
          {content}
        </ReactMarkdown>
      </StyledEligibilityClaim>
    </Container>
  );
};

export default EligibilityClaim;
