'use client';

import PropTypes from 'prop-types';
import Section from '@/components/ui/Section';
import { Container } from '@/components/ui/flexbox';
import { TestimonialsSlider } from '@/app/components/shared';

const Testimonials = ({ content }) => {
  if (!content?.quotes?.length) return null;

  return (
    <Section>
      <Container large>
        <TestimonialsSlider {...content} />
      </Container>
    </Section>
  );
};

Testimonials.propTypes = {
  content: PropTypes.shape({
    quotes: PropTypes.arrayOf(
      PropTypes.shape({
        author: PropTypes.string,
        id: PropTypes.number,
        quote: PropTypes.string,
        role: PropTypes.string,
        picture: PropTypes.shape({
          data: PropTypes.arrayOf(
            PropTypes.shape({
              attributes: PropTypes.shape({
                url: PropTypes.string,
                alternativeText: PropTypes.string,
              }),
            }),
          ),
        }),
      }).isRequired,
    ),
  }),
};

export default Testimonials;
