import { RemoveScroll } from 'react-remove-scroll';
import ReactModal from 'react-modal';

const defaultStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    zIndex: '1000000',
  },
  content: {
    alignContent: 'center',
    display: 'grid',
    justifyItems: 'center',
    border: 'none',
    overflow: 'initial',
    backgroundColor: 'transparent',
    padding: 0,
    inset: 0,
  },
};

export default function Modal({ children, style, isOpen, onRequestClose, ...props }) {
  return (
    <RemoveScroll enabled={isOpen} removeScrollBar={false}>
      <ReactModal
        style={style || defaultStyle}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
        {...props}
      >
        {children}
      </ReactModal>
    </RemoveScroll>
  );
}
