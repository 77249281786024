import React, { useId, useMemo } from 'react';
import cn from 'classnames';
import styled, { css } from 'styled-components';
import AnimateHeight from 'react-animate-height';

import Content from '@/components/core/Accordion/Content';

export const customAccordionSpacings = css`
  padding: 16px 0;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding: 27px 64px;
  }
`;

const TitleWrapper = styled.h3`
  ${customAccordionSpacings};
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &[aria-expanded='true'] {
    margin-bottom: 0;
  }
`;

const TitleText = styled.span`
  font-family: ${(props) => props.theme.typography.family.title};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  padding-right: 16px;

  &.is-open {
    color: ${(props) => props.theme.colors.grey.dark};
  }
`;

const TitleIcon = styled.i`
  min-width: 15px;
  min-height: 15px;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 2px;
    background-color: ${(props) => props.theme.colors.grey[500]};
    border-radius: 120px;
    transition: transform 500ms;
  }

  &:after {
    transform: translate(-50%, -50%) rotate(270deg);
  }

  &.is-open:after {
    transform: translate(-50%, -50%) rotate(0deg);
    background-color: #1d3861;
  }
`;

const ContentWrapper = styled.div`
  ${customAccordionSpacings};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    padding-top: 0;
  }
`;

const AccordionItemStyled = styled.li`
  border-bottom: 1px solid ${(props) => (props.transparentBorders ? 'transparent' : '#e7eaf0')};

  &:first-child {
    border-top: 1px solid ${(props) => (props.transparentBorders ? 'transparent' : '#e7eaf0')};
  }
`;

export default function AccordionItem({
  className,
  id,
  title,
  children,
  expanded = false,
  handleClick,
  transparentBorders,
}) {
  const uniqueId = useId();
  const panelId = useMemo(() => `panel-${id || 'accordion'}-${uniqueId}`, [id, uniqueId]);

  return (
    <AccordionItemStyled className={className} transparentBorders={transparentBorders}>
      <TitleWrapper
        id={`accordion-title-wrapper-${id}`}
        className="title-wrapper"
        aria-expanded={expanded}
        role="button"
        aria-controls={panelId}
        onClick={() => handleClick()}
      >
        <TitleText id={`accordion-title-text-${id}`} className={cn({ 'is-open': expanded })}>
          {title}
        </TitleText>
        <TitleIcon id={`accordion-icon-${id}`} className={cn({ 'is-open': expanded })} />
      </TitleWrapper>

      <AnimateHeight
        id={panelId}
        duration={500}
        height={expanded ? 'auto' : 0}
        aria-hidden={!expanded}
      >
        <ContentWrapper>
          <Content id={`accordion-content-${id}`}>{children}</Content>
        </ContentWrapper>
      </AnimateHeight>
    </AccordionItemStyled>
  );
}
