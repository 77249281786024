import styled from 'styled-components';

import PlayIcon from '@/public/assets/shapes/play-button.svg';

const PlayButton = ({ onClick }) => (
  <Button type="button" onClick={onClick} aria-label="Open movie testimonial">
    <PlayIcon />
  </Button>
);

const Button = styled.button`
  position: absolute;
  width: 48px;
  height: 48px;
  top: 48px;
  left: 48px;
  transform: scale(1);
  transition: transform 0.3s ease;
  z-index: 1000;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    top: 24px;
    left: 24px;
  }
`;

export default PlayButton;
