import styled from 'styled-components';
import Title from '@/components/ui/Title';
import Image from 'next/image';

export const Metrics = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 40px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    grid-template-columns: 1fr;
  }
`;
export const CustomTitle = styled(Title)`
  padding-bottom: 64px;
  max-width: 640px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-bottom: 40px;
    line-height: 32px;
  }
`;

export const ArrowDownIcon = styled(Image)`
  transform: rotateX(180deg);
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
  font-size: 25px;
  font-weight: 300;
  line-height: 32px;
  font-family: ${(props) => props.theme.typography.family.title};

  img {
    object-fit: contain;
    object-position: center center;
  }
`;
export const PercentageValue = styled.div`
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 45px;
  font-weight: 600;
  line-height: 56px;
  font-family: ${(props) => props.theme.typography.family.title};
`;

export const Description = styled.div`
  font-family: ${(props) => props.theme.typography.family.title};
  line-height: 24px;
  font-weight: 500;
  font-size: 18px;
  font-style: normal;

  sup {
    font-size: 60%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-left: 0;
  }
`;

export const Label = styled.div`
  text-transform: uppercase;
  font-family: ${(props) => props.theme.typography.family.title};
  color: ${({ theme }) => theme.colors.grey.default};
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 2px;
  margin-bottom: 24px;
`;
