import Script from 'next/script';
import { useEffect } from 'react';
import theme from '@/utils/styles-variables';

const Zendesk = ({ zendeskKey, department }) => {
  const key = zendeskKey || process.env.NEXT_PUBLIC_ZENDESK_KEY;

  useEffect(() => {
    if (key) {
      window.zESettings = {
        webWidget: {
          color: {
            theme: theme.colors.primary.default,
            launcherText: theme.colors.white,
          },
          chat: {
            departments: {
              enabled: [''],
              // enabled: [department],
              select: department,
            },
          },
        },
      };
    }
  }, []);

  return key ? (
    <Script id="ze-snippet" src={`https://static.zdassets.com/ekr/snippet.js?key=${key}`} />
  ) : null;
};

export default Zendesk;
