import styled from 'styled-components';
import { Container } from '@/components/ui/flexbox';

export const CustomContainer = styled(Container)`
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    margin: 0;
    padding: 0;
  }
`;

export const InnerWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.neutral.default};
  font-family: ${({ theme }) => theme.typography.family.title};
  text-align: center;
  padding: 88px 108px;
  position: relative;
  overflow: hidden;
  border-radius: 16px;

  .large {
    position: absolute;
    left: -92px;
    bottom: -56px;
  }

  .small {
    position: absolute;
    left: 150px;
    bottom: 40px;
  }

  .outline {
    position: absolute;
    right: -88px;
    top: -104px;
  }

  h4 {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 24px;

    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      font-size: 25px;
      line-height: 32px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 64px 56px;

    .large {
      left: -92px;
      bottom: -80px;
    }

    .small {
      left: 128px;
      bottom: 52px;
    }

    .outline {
      right: -118px;
      top: -131px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 64px 24px;
    border-radius: 0;

    img {
      transform: scale(0.53);
    }

    .large {
      left: -78px;
      bottom: -93px;
    }

    .small {
      left: 69px;
      bottom: 25px;
    }

    .outline {
      right: -100px;
      top: -130px;
    }
  }
`;
