import React from 'react';

import { ButtonStyle } from './styles';

const Button = React.forwardRef(
  ({ big, small, children, onClick, className, type, ...props }, ref) => {
    return (
      <ButtonStyle
        ref={ref}
        className={className}
        type={type ? type : 'button'}
        small={small}
        big={big}
        onClick={onClick}
        {...props}
      >
        {children}
      </ButtonStyle>
    );
  },
);

Button.displayName = 'Button';

export default Button;
