import styled, { css } from 'styled-components';

export const ButtonStyle = styled.button`
  height: 40px;
  padding: 0 16px;
  border-radius: 100px;
  font-family: ${(props) => props.theme.typography.family.title};
  font-weight: 500;
  font-size: 14px;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;

  ${(props) =>
    props.big &&
    css`
      height: 48px;
      padding: 0 32px;
      font-size: 16px;
    `}

  ${(props) =>
    props.small &&
    css`
      padding: 0 11px;
      height: 50px;
    `}
`;
