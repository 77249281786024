import { useContext } from 'react';
import Image from 'next/image';

import { products } from '@/utils/products';
import { InnerWrapper, CustomContainer } from './styles';

import EnrollButton from '@/app/components/core/EnrollButton';
import ClientContext from '@/contexts/client.context';
import Section from '@/components/ui/Section';

export default function HighlightedCTA({ content, scrollTop = false, referralUser }) {
  const { client, openModal, selectClientCountry } = useContext(ClientContext);
  const {
    title,
    button: { label },
  } = content;

  const handleScrollTop = (scrollTop) => {
    if (client.clientKey && !client.countries?.length) {
      return false;
    }
    if (client.clientKey && client.countries.length > 2 && selectClientCountry.country) {
      return false;
    }

    return scrollTop;
  };

  return (
    <Section>
      <CustomContainer large>
        <InnerWrapper>
          <Image
            className="large"
            alt=""
            src={`/assets/shapes/cta-full-circle.svg`}
            width="184"
            height="184"
          />
          <Image
            className="small"
            alt=""
            src={`/assets/shapes/cta-dark-circle.svg`}
            width="16"
            height="16"
          />
          <Image
            className="outline"
            alt=""
            src={`/assets/shapes/cta-outline-circle.svg`}
            width="215"
            height="215"
          />
          <h4 id={'highlighted-cta-title'}>{title}</h4>

          <EnrollButton
            id="highlighted-cta"
            isActive={client.isActive}
            buttonLocation="highlighted-cta"
            openModal={() => openModal({ buttonText: label, buttonLocation: 'highlighted-cta' })}
            client={client}
            buttonLabel={label}
            scrollTop={handleScrollTop(scrollTop)}
            selectClientCountry={selectClientCountry}
            triage={client?.hasTriage}
            product={products[client?.product?.key?.toUpperCase()]}
            redirectToApp={client?.redirectToApp}
            referralUser={referralUser}
          />
        </InnerWrapper>
      </CustomContainer>
    </Section>
  );
}
