import theme from '@/utils/styles-variables';

const wysiwyg = () => {
  return `
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        color: #1F222C;
        
        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;
          color: #1F222C;
        }
        
        strong {
          font-weight: 700;
        }
        
        em {
          font-style: italic;
        }
        
        del {
          text-decoration: line-through;
        }
        
        h3 {
          font-size: 22px;
          line-height: 26px;
          margin-bottom: 20px;
        }
        
        h4 {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 18px;
        }
        
        h5 {
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 18px;
        }
        
        h6 {
          font-size: 16px;
          line-height: 26px;
          margin-bottom: 18px;
          text-transform: uppercase;
        }

        a {
            display: inline;
            color: ${theme.colors.primary.default};
            cursor: pointer;
            text-decoration: underline;

            &:hover{
              color: ${theme.colors.primary.dark};
            }
        }
        
        blockquote {
          display: block;
          margin: 0;
          border-left: 2px solid #F46262;
          padding-left: 25px;
        }
        
        ol,
        ul {
            display: block;
            margin: 0;
        }
        
        ul {
            li {
                list-style-type: none;
                padding-left: 24px;
                position: relative;
                
                &:not(:last-child){
                  margin-bottom: 24px;
                }
                
                &:before {
                  position:absolute;
                  left: 0;
                  top: 8px;
                  width: 8px;
                  height: 8px;
                  content: '';
                  border-radius: 50%;
                  background-color: ${theme.colors.primary.default};
              }
            }
        }
        
        ol {
            li {
                list-style-type: decimal;
                margin-left: 20px;
                
                &:not(:last-child){
                  margin-bottom: 15px;
                }
            }
        }
 
      code {
        display: block;
        font-family: monospace;
        padding: 20px;
        background-color: #F7F4F2;
      }
       
    `;
};

export { wysiwyg };
