import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from 'react';

import cn from 'classnames';

import getImgSrcWorkaround from '@/utils/images';

import { CustomImage, TempImageWrapper, VideoContainer, Wrapper, WrapperVideos } from './styles';

const DynamicImage = ({ content, selected, index, isDesktop }) => {
  const { image, video } = content;
  const show = isDesktop ? selected : selected === index;
  const [animationMobile, setAnimationMobile] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView && index !== undefined && !isDesktop && !animationMobile) {
      setAnimationMobile(true);
    }
  }, [inView]);

  return (
    <Wrapper ref={ref}>
      <TempImageWrapper className={cn({ selected })} $mobileSpace={index !== 0}>
        {video.data?.attributes?.url ? (
          <WrapperVideos $mobileSpace={index !== 0}>
            <VideoContainer autoPlay playsInline loop muted>
              <source src={video.data?.attributes?.url} />
            </VideoContainer>
          </WrapperVideos>
        ) : (
          <CustomImage
            $active={true}
            src={getImgSrcWorkaround(image?.data?.attributes.url)}
            alt={image?.data?.attributes.alternativeText || ''}
            selected={!isDesktop ? animationMobile : show}
            fill
            sizes="(max-width: 767px) 100vw, (max-width: 991px) 50vw, 683px"
          />
        )}
      </TempImageWrapper>
    </Wrapper>
  );
};

export default DynamicImage;
