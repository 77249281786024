import { useState } from 'react';

import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

import {
  CustomSection,
  CustomContainer,
  ImageSticky,
  Indicator,
  MobileRow,
  SemiCircle,
  TextCol,
  MobileContainer,
} from './styles';
import { Col, Row } from '@/components/ui/flexbox';
import PanelText from './PanelText/PanelText';
import DynamicImage from './DynamicImage/DynamicImage';
import classNames from 'classnames';

const StickyPanel = ({ content }) => {
  const [selected, setSelected] = useState(0);
  const isDesktop = useBetterMediaQuery(`(min-width: ${theme.breakpoints.min_xl}px)`);

  return (
    <CustomSection colored={true}>
      <CustomContainer>
        <Row>
          <Col xs={5}>
            {content.map((solution, index) => (
              <PanelText
                key={solution.id}
                index={index}
                select={setSelected}
                content={solution}
                isDesktop={isDesktop}
              />
            ))}
          </Col>
          <Col xs={7}>
            <ImageSticky>
              {content.map((solution, index) => (
                <DynamicImage
                  key={solution.id}
                  content={solution}
                  selected={selected === index}
                  index={index}
                  isDesktop={isDesktop}
                />
              ))}
              <Indicator>
                {Array(content.length)
                  .fill(0)
                  .map((_, index) => (
                    <SemiCircle
                      key={index}
                      className={classNames({ active: index === selected })}
                      data-testid={index}
                    />
                  ))}
              </Indicator>
            </ImageSticky>
          </Col>
        </Row>
      </CustomContainer>
      <MobileContainer>
        {content.map((solution, index) => {
          return (
            <MobileRow key={solution.id}>
              <TextCol md={6} xs={12}>
                <PanelText
                  select={setSelected}
                  content={solution}
                  index={index}
                  isDesktop={isDesktop}
                />
              </TextCol>
              <Col md={6} xs={12}>
                <DynamicImage
                  key={solution.id}
                  content={solution}
                  selected={selected === index}
                  index={index}
                  isDesktop={isDesktop}
                />
              </Col>
            </MobileRow>
          );
        })}
      </MobileContainer>
    </CustomSection>
  );
};

export default StickyPanel;
