import { useContext } from 'react';
import Section from '@/components/ui/Section';
import { Container } from '@/components/ui/flexbox';
import MarkdownRichText from '../MarkdownRichText';

import { Box, Info, Item, Label } from './styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import ClientContext from '@/contexts/client.context';

const FaqBanner = ({ content, contact }) => {
  const { client } = useContext(ClientContext);

  if (!content || !contact || !content.active) return null;

  const { title } = content;
  const { phone, email, label } = contact;
  const currentProductTheme = client.product.theme;

  return (
    <Section lastColored>
      <Container small>
        <Box $product={currentProductTheme}>
          <Label>
            <MarkdownRichText elements={['a']}>{title}</MarkdownRichText>
          </Label>
          {(phone || email) && (
            <Info>
              {phone && (
                <Item>
                  <FontAwesomeIcon icon={faPhone} className="icon" />
                  <a href={`tel:${phone.href.replace(/\s/g, '')}`}>{phone.label}</a> {label}
                </Item>
              )}
              {email && (
                <Item>
                  <FontAwesomeIcon icon={faEnvelope} className="icon" />
                  <a href={`mailto:${email.href}`}>{email.label}</a>
                </Item>
              )}
            </Info>
          )}
        </Box>
      </Container>
    </Section>
  );
};
export default FaqBanner;
