import styled from 'styled-components';
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 406px;
  padding-top: 160px;
  padding-bottom: 220px;

  &:first-child {
    padding-top: 200px;
  }

  .label {
    padding: 0;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_lg}px) {
    padding-top: 0 !important;
    padding-bottom: 0;
    margin-right: 13px;
  }
`;
