import styled from 'styled-components';

export const Box = styled.div`
  padding: 48px 24px;
  display: flex;
  gap: 16px;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  position: relative;
  background-color: ${(props) => props.theme.colors.primary.light};
`;

export const Label = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  font-family: ${(props) => props.theme.typography.family.title};

  a {
    text-decoration: underline;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    gap: 16px;
  }
`;

export const Item = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  svg {
    margin-right: 4px;
  }

  a {
    transition: all 0.3s ease;
    &:hover {
      text-decoration: underline;
    }
  }
`;
