import { useContext, useMemo } from 'react';
import Image from 'next/image';

import EnrollButton from '@/app/components/core/EnrollButton';
import { MarkdownRichText, Video } from '@/app/components/shared';
import { Paragraph, RichText } from '@swordhealth/ui-corporate';

import {
  CustomContainer,
  Wrapper,
  Text,
  SignIn,
  EligibilityClaim,
  StyledTitle,
  ImageWrapper,
} from './styles';
import theme from '@/utils/styles-variables';

import { createSignInURL } from '@/utils/onboarding-urls';
import getImgSrcWorkaround, { getImgData } from '@/utils/images';
import useBetterMediaQuery from '@/utils/useBetterMediaQuery';

import ClientContext from '@/contexts/client.context';
import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';
import Section from '@/components/ui/Section';
import { compareDates } from '@/utils/dates';
import { Logos } from '@/app/components/shared/Logos/Logos';

const onboardingBaseURL = process.env.NEXT_PUBLIC_ONBOARDING_BASE_URL
  ? process.env.NEXT_PUBLIC_ONBOARDING_BASE_URL
  : 'https://onboarding.swordhealth.com/';

const BUTTON_LABEL = 'Sign in here';

const Header = ({ content, logo, referralUser, showSwordLogo = true }) => {
  const { title, subtitle, eligibilityClaim, button, previewVideo, video, videoTitle, images } =
    content;
  const { openModal, client } = useContext(ClientContext);
  const hasVideo = !!(previewVideo && video && videoTitle);
  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  const shownImage =
    isMobile && images?.imageMobile && images?.imageMobile?.data
      ? getImgData(images?.imageMobile)
      : getImgData(images?.image);

  const logos = useMemo(() => {
    if (!logo?.data) {
      return [];
    }
    if (Array.isArray(logo.data)) {
      return logo.data.slice(0, 3);
    }
    return [logo.data];
  }, [logo]);

  const signInURL = createSignInURL(onboardingBaseURL, client.clientKey);

  return (
    <Section colored={referralUser}>
      <CustomContainer>
        <Wrapper $video={hasVideo}>
          <Logos logos={logos} centered showSwordLogo={showSwordLogo} client={client} />
          <StyledTitle id="header_title">
            <MarkdownRichText elements={['h1', 'u', 'a', 'sup']}>
              {referralUser
                ? title.replace('{{user.inviter_first_name}}', referralUser?.inviter_first_name)
                : title}
            </MarkdownRichText>
          </StyledTitle>
          <Text id="header_subtitle">
            {referralUser
              ? subtitle?.replace('USER_NAME', referralUser?.inviter_first_name)
              : subtitle}
          </Text>

          <EnrollButton
            id="header_button"
            isActive={client.isActive}
            buttonLocation="header"
            openModal={() => openModal({ buttonText: button.label, buttonLocation: 'header' })}
            client={client}
            buttonLabel={button?.label}
            product={client?.product}
            redirectToApp={client?.redirectToApp}
            buttonApp={button}
            triage={client?.hasTriage}
            referralUser={referralUser}
          />

          {!referralUser && client.isActive && !compareDates(client.launchDate) && logos && (
            <SignIn id="header_sign_in">
              <RichText as={Paragraph}>
                <span id="header_sign_in_text">Already have an account?&nbsp;</span>
                <a
                  id="header_sign_in_link"
                  href={signInURL}
                  onClick={() => {
                    trackButtonClicked({
                      action: ACTIONS.REDIRECT_TO_SIGN_IN,
                      buttonType: BUTTON_TYPES.NAVIGATION,
                      buttonText: BUTTON_LABEL,
                      destination: signInURL,
                      buttonLocation: 'header',
                    });
                  }}
                >
                  {BUTTON_LABEL}
                </a>
              </RichText>
            </SignIn>
          )}
          {eligibilityClaim && !referralUser && (
            <EligibilityClaim>
              <MarkdownRichText elements={['p', 'strong', 'em', 'a', 'u']}>
                {eligibilityClaim}
              </MarkdownRichText>
            </EligibilityClaim>
          )}
        </Wrapper>
        {shownImage && shownImage?.url && (
          <ImageWrapper>
            <Image
              id="hero-image"
              src={getImgSrcWorkaround(shownImage?.url)}
              alt={shownImage?.alternativeText || ''}
              loading="eager"
              objectFit="contain"
              fill
            />
          </ImageWrapper>
        )}
      </CustomContainer>
      {hasVideo && <Video previewVideo={previewVideo} video={video} videoTitle={videoTitle} />}
    </Section>
  );
};

export default Header;
