import styled, { css } from 'styled-components';
import Slider from 'react-slick';
import Title from '@/components/ui/Title';
import { MarkdownRichText } from '@/app/_components/shared';

export const CardWrapper = styled.div`
  width: 400px;
  height: 450px;
  overflow: hidden;
  border-radius: 16px;
  margin-bottom: 30px;
  background-color: ${(props) => props.theme.colors.neutral.default};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 296px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: auto;
    max-width: 100%;
    min-height: 366px;
    padding-bottom: 32px;
    margin: 0;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 267px;

  img {
    border-radius: 8px 8px 0 0;
    object-fit: cover;
    object-position: top;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 296px;
    height: 219px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    width: 100%;
    height: 190px;
  }
`;

export const TextWrapper = styled.div`
  padding: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    padding: 24px 24px 0;
  }
  h4 {
    padding-bottom: 16px;
  }
`;

export const CustomTitle = styled(Title)`
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 16px;
`;

export const Description = styled(MarkdownRichText)`
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
`;

export const StyledSlide = styled(Slider)`
  position: absolute;
  top: 0;
  left: 516px;
  width: 70vw;
  overflow: hidden;
  .slick-track {
    display: flex;
    gap: 24px;
  }

  .slick-slide {
    height: inherit !important;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    position: relative;
    left: 0;
    width: 100%;
  }
`;
export const SlideWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: flex;
    flex-direction: column-reverse;
    margin-right: -36px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    position: relative;
    margin-right: unset;
    & .slick-initialized {
      margin: 8px 0px 33px;
    }
    & .slick-dots {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      height: 32px;
    }
    & .slick-dots li {
      width: unset;
      height: unset;
      margin: 0 4px;
    }
    & .slick-dots button {
      width: 8px;
      height: 8px;
      box-sizing: border-box;
      border: 2px solid ${(props) => props.theme.colors.grey[500]};
      padding: 0;
      border-radius: 100%;
    }
    & .slick-dots button::before {
      content: none;
    }
    & .slick-dots .slick-active button {
      background-color: ${(props) => props.theme.colors.grey[500]};
    }
  }
`;

export const ArrowsWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: -6px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    display: none;
  }
`;
export const CenteredArrowButton = styled.button`
  width: 48px;
  height: 48px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colors.grey.dark};
  border-radius: 50%;
  transition: all 0.3s ease;
  margin-right: 12px;

  ${(props) =>
    props.$flip &&
    css`
      transform: scaleX(-1);
    `};

  &:hover {
    border-color: ${(props) => props.theme.colors.grey.default};
  }
`;
export const CardContainer = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    padding-bottom: 18px;
  }
`;
