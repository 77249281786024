import styled from 'styled-components';
import { H3 } from '@/components/ui/Title';

export const Header = styled.header`
  text-align: center;
`;
export const HeaderWrapper = styled.div`
  width: 100%;
  max-width: 690px;
  margin: 0 auto;
`;
export const Title = styled(H3)`
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    font-size: 48px;
    padding-bottom: 24px;
  }
`;
