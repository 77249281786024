import { CustomGradientRingShape, CustomCircleFilled } from './styles';
import theme from '@/utils/styles-variables';

const GraphicElements = () => {
  return (
    <>
      <CustomGradientRingShape color={theme.colors.primary[50]} />
      <CustomCircleFilled color={theme.colors.secondary.light} />
    </>
  );
};

export default GraphicElements;
