import styled, { css } from 'styled-components';

export const CardList = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    row-gap: 64px;

    > * {
      width: calc(50% - 20px);
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    column-gap: 40px;
    row-gap: 40px;
    > * {
      width: 100%;
    }
  }
`;

export const ImageBox = styled.div`
  aspect-ratio: 5 / 4;
  position: relative;
`;

export const MobileImageBox = styled(ImageBox)`
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    display: none;
  }
`;

export const DesktopImageBox = styled(ImageBox)`
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: none;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    > * {
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

export const ImageWrapper = styled.figure`
  aspect-ratio: 5 / 4;
  position: relative;

  img {
    object-fit: cover;
    border-radius: 16px;
    overflow: hidden;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-bottom: 8px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 80px;
  flex-direction: row;
  margin-top: 48px;

  ${(props) =>
    props.reverse &&
    css`
      flex-direction: row-reverse;
    `};

  ${CardList} {
    width: 33%;
  }

  ${ImageBox} {
    width: 66%;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin-top: 16px;
    ${ImageBox} ,
    ${CardList} {
      width: 100%;
    }
  }
`;
export const TitleWrapper = styled.div`
  ${(props) =>
    props.reverse &&
    css`
      width: calc(66% - 40px);
    `};

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    width: 100%;
  }
`;
