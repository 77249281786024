export function scrollToSection({ sectionId, isReduced }) {
  const section = document.getElementById(sectionId);

  if (!section) return;

  section.scrollIntoView({ behavior: isReduced ? 'instant' : 'smooth' });
}

export function scrollToTop({ isReduced }) {
  scroll({ top: 0, behavior: isReduced ? 'instant' : 'smooth' });
}
