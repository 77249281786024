import Section from '@/components/ui/Section';
import Header from './Header';
import CardsSlider from './Cards/CardsSlider';

import { Wrapper } from './styles';

const SideSlider = ({ content }) => {
  if (!content) return null;

  return (
    <Section>
      <Wrapper large>
        <Header content={content} />
        <CardsSlider cards={content?.cards} />
      </Wrapper>
    </Section>
  );
};
export default SideSlider;
