import styled from 'styled-components';

export const StyledDisclaimer = styled.div`
  padding: ${(props) => (props.$hasEligibilityClaim ? '16px 0 32px' : '32px 0')};
  border-bottom: 1px solid ${(props) => props.theme.colors.grey.light};

  p {
    font-weight: 500;
    line-height: 20px;
    font-size: 12px;
    color: ${(props) => props.theme.colors.grey.default};
    max-width: ${(props) => props.theme.flexboxgrid.containerWidth}px;

    a {
      text-decoration: underline;
    }
  }
`;
