import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  overflow: clip;
`;
export const Container = styled.div`
  overflow: clip;
  z-index: 2;
  position: relative;
  background-color: ${(props) => props.theme.colors.neutral.default};
`;
