import styled from 'styled-components';
import { H3 } from '@/components/ui/Title';
import { Heading } from '@swordhealth/ui-corporate';

export const Title = styled(H3)`
  margin: 0 auto;
  max-width: 640px;
  text-align: center;
  padding-bottom: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    text-align: left;
    margin: unset;
  }
`;

export const Subtitle = styled.h6`
  margin: 0 auto;
  max-width: 640px;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  padding-top: 24px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    text-align: left;
    margin: 0;
    padding: 9px 0 24px;
  }
`;

export const Label = styled(Heading)`
  text-align: center;
  padding-top: 40px;

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    text-align: left;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  padding-top: 80px;
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 20px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: ${(props) => (props.$cards === 4 ? `calc(25% - 18px)` : `calc(100% / 3 - 16px)`)};
  padding: 32px;
  background-color: ${(props) => props.theme.colors.neutral.default};

  @media (max-width: ${({ theme }) => theme.breakpoints.max_md}px) {
    padding: 24px;
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.max_sm}px) {
    height: 100%;
  }
`;

export const CardTitle = styled.h6`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  font-family: ${(props) => props.theme.typography.family.title};
`;

export const CardDesc = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`;

export const SliderStyle = styled.div`
  .embla__container {
    align-items: stretch;
  }

  .embla__slide {
    height: auto;
    min-height: 100%;
  }
`;
