import Section from '@/components/ui/Section';
import { Container } from '@/components/ui/flexbox';
import TestimonialsSlider from '../../shared/TestimonialsSlider';
import { CustomCard } from './Card';
import { SliderStyle, Subtitle, Title, Grid, Label } from './style';

import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';

export default function GridCards({ content }) {
  const { title, subtitle, cards, extraLabel } = content;

  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  return (
    <Section>
      <Container large>
        <Title id="grid_cards_title">{title}</Title>
        <Subtitle id="grid_cards_subtitle">{subtitle}</Subtitle>

        {isMobile ? (
          <SliderStyle>
            <TestimonialsSlider quotes={cards} CustomCard={CustomCard} />
          </SliderStyle>
        ) : (
          <Grid>
            {cards.map((item) => (
              <CustomCard
                key={item.id}
                {...item}
                id={`grid_cards_${item.id}`}
                cards={cards.length}
              />
            ))}
          </Grid>
        )}
        <Label variant="6">{extraLabel}</Label>
      </Container>
    </Section>
  );
}
