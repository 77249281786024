import styled from 'styled-components';

import theme from '@/utils/styles-variables';

import Button from './Button';

export const TertiaryButton = styled(Button).attrs({
  loadingColor: theme.colors.white,
})`
  border: 1px solid ${(props) => props.theme.colors.grey.dark};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.grey.dark};
  }
  &:disabled {
    pointer-events: none;
    background-color: #e7eaf0;
    color: #747986;
  }
`;
