import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGFM from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
import { scrollToSection } from '@/utils/scrollToSection';
import useReducedMotion from '@/utils/useReducedMotion';

const MarkdownRichText = ({
  components = {},
  elements = [],
  rehypePlugins = [],
  children,
  ...rest
}) => {
  const isReduced = useReducedMotion();
  const finalElements = useMemo(() => ['strong', 'sub', 'sup', ...elements], [elements]);
  const finalRehypePlugins = useMemo(() => [rehypeRaw, ...rehypePlugins], [rehypePlugins]);
  const finalComponents = useMemo(
    () => ({
      ...components,
      // eslint-disable-next-line no-unused-vars
      a: ({ node, href, ...props }) => {
        try {
          if (href.startsWith('#')) {
            return (
              <a
                {...props}
                href={href}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection({ sectionId: href.replace('#', ''), isReduced });
                }}
              >
                {props.children}
              </a>
            );
          }
          const hasLinks = !!href && !!process.env.NEXT_PUBLIC_DEPLOYMENT_URL;
          const isExternal =
            hasLinks &&
            new URL(href).hostname !== new URL(process.env.NEXT_PUBLIC_DEPLOYMENT_URL).hostname;

          if (!isExternal) {
            throw new Error('Not an external link');
          }

          return (
            <a {...props} href={href} target="_blank" rel="noopener noreferrer">
              {props.children}
            </a>
          );
        } catch {
          return (
            <a {...props} href={href}>
              {props.children}
            </a>
          );
        }
      },
    }),
    [components],
  );

  if (!children) return null;

  return (
    <ReactMarkdown
      {...rest}
      remarkPlugins={[[remarkGFM, remarkBreaks]]}
      rehypePlugins={finalRehypePlugins}
      unwrapDisallowed
      allowedElements={finalElements}
      components={finalComponents}
    >
      {children}
    </ReactMarkdown>
  );
};

export default MarkdownRichText;
