import styled from 'styled-components';
import { Label } from '@/components/ui/Title';
import { Container } from '@/components/ui/flexbox';

export const Wrapper = styled(Container)`
  min-height: 450px;
  position: relative;

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    .slick-slider .slick-dots {
      bottom: 0 !important;
    }
  }
`;

export const HeaderWrapper = styled.div`
  max-width: 400px;

  ${Label} {
    padding-bottom: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 456px;

    ${Label} {
      padding-bottom: 64px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    max-width: 100%;

    ${Label} {
      padding-bottom: 40px;
    }
  }
`;

export const CustomContainer = styled(Container)`
  position: relative;
`;
